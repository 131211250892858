.pageHeader .headerContent > *:not(.mainLogo) {
  margin: 0; /*overwrite Fresco default */
}
@media (min-width: $nav-breakpoint) {
  .pageHeader .headerContent {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
li.level1.hasSubmenu:hover > a:after {
  color: $black;
}
.mainLogo {
  @media (min-width: $nav-breakpoint) {
    margin: 0 20px 0 40px;
  }
}
li#menu42abc392-85ff-455d-bc65-5b9188f9a523 {
  @media (min-width: $nav-breakpoint) {
    margin-right: 20px;
  }
  a {
  background-color: $brand-orange;
  color: $black;
  }
  > a {
    font-weight: bold;
  }
}
.mainCallToActionButtons {
  display: none!important; // Hide main call to action buttons, cannot remove snippet or mobile nav doesn't work!!!
}
.carousel {
  padding-top: 20px;
}
.raisedWrapper .totaliserFigure {
  color: $brand-primary;
}

.commentDonationAmount {
  color: $brand-primary;
}
.tab {
  &.cpt {
    background-color: $brand-orange;
    a {
    color: $black;
    font-weight: bold;
    }
    &:hover {
      background-color: darken($brand-orange, 10%);
    }
  }
  &.ft {
    background-color: $brand-primary;
    a {
      color: white;
      font-weight: bold;
    }
    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}

.appealsPostBody .carouselSlide {
  margin: 0 0 20px;
}

.post .contentBlockWrapper.appealActionsWrapper {
  margin-bottom: 20px;
}

.pageFooter {
  padding-bottom: 0;
  font-size: .8em;
}
.footerBox1, .footerBox2 {
  padding: 30px;
}
.footerBox1 {
  background-color: #222222;
  h2 {
    color: white;
    border-color: #be1622;
    border-width: 0 0 0 3px;
    border-style: solid;
    padding-left: 15px;
    line-height: 1;
    display: block;
    font-size: 1.15em;
    font-weight: normal;
  }
  img {
    max-width: 300px;
    @media (min-width: 768px) {
      float: right;
    }
    box-shadow: 0px 0px 12px 8px rgba(212,212,212,0.42);
    border-radius: 4px;
  }
}
.footerBox2 {
  background-color: #1b1b1b;
  margin-top: 0!important;
  position: relative;
  &:after {
    position: absolute;
    right: 30px;
    bottom: 20px;
    content: '';
    background-image: url($assets-path + 'cyberessentials_certification mark plus_colour.png');
    width: 59px;
    height: 70px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 768px) {
  .footerBox > *{
    float: left;
    width: 50%;
  }
}
ul.footerLinks {
  @media (min-width: 768px) {
    width: 50%;
  }
  display: inline-flex;
  padding-right: 50px;
  li {
    padding: 0 15px 0 0;
  }
}
.copyright {
  color: #b1b4b8;
}